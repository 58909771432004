export enum CLASSES {
  PADDLE_BUTTON = 'paddle_button',
  PADDLE_FRAME = 'paddle-frame',
  PADDLE_LOADER = 'paddle-loader',
  PADDLE_FRAME_INLINE = 'paddle-frame-inline',
  PADDLE_FRAME_OVERLAY = 'paddle-frame-overlay',
  PADDLE_STYLED_BUTTON = 'paddle_styled_button',
  GREEN = 'green',
  LIGHT = 'light',
  DARK = 'dark',
}

export enum URL_PARAMS {
  PADDLE_ENVIRONMENT = 'paddle_env',
  PADDLE_TRANSACTION = '_ptxn',
  RETAIN_IDENTIFIER = '__pw_iic',
  SHORT_RETAIN_IDENTIFIER = '__pw',
}

export enum DATA_ATTRIBUTES {
  DATA_PADDLE_VERSION = 'data-paddle-version',
  DATA_INIT = 'data-init',
  DATA_DISCOUNT_ID = 'data-discount-id',
  DATA_DISCOUNT_CODE = 'data-discount-code',
  DATA_TRANSACTION_ID = 'data-transaction-id',
  DATA_ITEMS = 'data-items',
  DATA_CUSTOMER_AUTH_TOKEN = 'data-customer-auth-token',
  DATA_SAVED_PAYMENT_METHOD_ID = 'data-saved-payment-method-id',

  DATA_CUSTOMER_ID = 'data-customer-id',
  DATA_CUSTOMER_EMAIL = 'data-customer-email',
  DATA_CUSTOMER_ADDRESS_ID = 'data-customer-address-id',
  DATA_CUSTOMER_ADDRESS_COUNTRY_CODE = 'data-customer-address-country-code',
  DATA_CUSTOMER_ADDRESS_POSTAL_CODE = 'data-customer-address-postal-code',
  DATA_BUSINESS_NAME = 'data-business-name',
  DATA_BUSINESS_ID = 'data-business-id',
  DATA_BUSINESS_TAX_ID = 'data-business-tax-id',
  DATA_CUSTOMER_ADDRESS_FIRST_LINE = 'data-customer-address-first-line',
  DATA_CUSTOMER_ADDRESS_CITY = 'data-customer-address-city',
  DATA_CUSTOMER_ADDRESS_REGION = 'data-customer-address-region',

  DATA_CUSTOM_DATA = 'data-custom-data',

  DATA_LOCALE = 'data-locale',
  DATA_DISPLAY_MODE = 'data-display-mode',
  DATA_THEME = 'data-theme',
  DATA_SHOW_ADD_DISCOUNTS = 'data-show-add-discounts',
  DATA_ALLOW_DISCOUNT_REMOVAL = 'data-allow-discount-removal',
  DATA_SHOW_ADD_TAX_ID = 'data-show-add-tax-id',
  DATA_SUCCESS_URL = 'data-success-url',
  DATA_ALLOW_LOGOUT = 'data-allow-logout',
  DATA_SOURCE_PAGE = 'data-source-page',
  DATA_ALLOWED_PAYMENT_METHODS = 'data-allowed-payment-methods',
}
